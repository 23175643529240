

import React from 'react';
import Speech from 'react-speech';
import { Link } from "react-router-dom";

// Components 
import Menu from '../components/menu';
// import weinacht_image from "../css/image/weinachten.jpg";


class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        window.scrollTo(0, 0)
        return (<div className="animate__animated animate__fadeIn" style={{ overflowX: "hidden" }}>

            <video className="start-video" autoPlay muted loop playsInline>
                <source src="/video1.mp4" type="video/mp4" />
                Dein Browser unterstützt das Video-Tag nicht.
            </video>



            <div className='start-menu'>
                <Menu {...this.props} />
            </div>




            {/*  <>
                <div className="pt-5 pb-5 weinachtsDiv">

                    <div className="container pt-5 pb-5">

                        <div className="row m-0">

                            <div className="col-12 col-md-6">
                                <img src={weinacht_image} className="rounded" alt="" width={"100%"} height="300px" style={{ objectFit: "cover" }} />
                            </div>

                            <div className="col-12 col-md-6 m-auto ff-3 winachtstext">
                                <p>Wir wünschen allen einen besinnlichen Advent, eine frohe Weihnachtszeit und alles Gute für das neue Jahr!</p>
                            </div>

                        </div>
                    </div>
                </div>
            </> */}

            <>

                <div className='container'>

                    <div className="block- text-center bg-white p-4 rounded shadow-sm mt-5" style={{ position: "relative" }}>
                        <span className="px-1 bg-info text-white rounded" style={{ rotate: "-15deg", position: "absolute", top: "5px", left: "5px" }}>Neu</span>

                        <h5>
                            Neueröffnung Tagespflege am Waldkater im Mai 2025
                        </h5>
                        <div>
                            Besichtigung am Tag der offenen Tür am <strong>27.04.2025, 15-18 Uhr</strong>. <br />
                            Weitere Informationen erhalten Sie bei <i className='text-secondary'>Miriam Böger 05751- 89 104 750</i>
                            <br />
                            <Link className="btn btn-info mt-2" to="/tagespflegeAmWaldkater">Tagespflege</Link>

                        </div>


                    </div>
                </div>



            </>





            <>
                <div className="ff-3" style={{ color: "#3364a0" }}>
                    <div className="block-small text-center m-0">
                        <h3 className="m-auto bg-white p-3 rounded shadow-sm">Mit-Menschen... Für Menschen</h3>
                    </div>
                </div>
            </>

            <div className='container mb-3'>

                <div className='row m-0'>
                    <div className='col-12 col-lg-6 p-4 row'>
                        <div className="p-3 shadow-sm bg-white text-dark startText mt-2 rounded">
                            <p className="container">
                                Lebenshilfe Rinteln e.V. ist eine Einrichtung zur Betreuung und Förderung von Menschen mit speziellen Bedürfnissen. Es gibt uns seit 1966.
                                Unsere Anfänge waren in angemieteten Räumen in der Altstadt von Rinteln. 1998 zogen wir von der Ostertorstraße in die Waldkaterallee auf das Gelände des ehem.
                                Britischen Militär Hospitals. Auf Grund der Möglichkeiten auf dem Gelände konnten wir weiter expandieren und neben der Platzzahlerhöhung von Frühförderung,
                                Heilpädagogischen Kindergarten und Tagesbildungsstätte auch drei Wohnheime (alles Einrichtungen speziell für Menschen mit Beeinträchtigungen) in Betrieb nehmen.
                                Am 01.09.2006 wurde der von der Heimaufsicht genehmigte Wohnbereich für Senioren mit geistiger Beeinträchtigung mit 24 Plätzen in Betrieb genommen.
                                Am 01.09.2015 hat unser Pflegeheim "Haus am Waldkater" seinen Betrieb aufgenommen und schon nach kurzer Zeit eine sehr gute Auslastung erreicht.
                                Am 01.09.2016 wurde der Regelkindergarten Integrative "Kindertagesstätte am Waldkater" eröffnet und im Jahr 2019 um eine Krippen- sowie die Integrationsgruppe erweitert.
                                Im Jahr 2021 erfolgte der Umzug des Seniorenwohnens in das frisch umgebaute Haus Antonia.
                                Seit 2024 bieten wir mit unserem Projekt „Stallpiraten“ den hier betreuten Menschen (pädagogisch) begleitete Interaktion mit unseren Tieren (zur Zeit Pferde, Ponys, Esel) an.
                                Heute werden bei uns ca. 500 Menschen in den verschiedenen Bereichen gefördert und betreut.
                                Im Mai 2025 soll unser neuer Bereich „Tagespflege am Waldkater“ eröffnet werden. Die Tagespflege ist ein Angebot für Menschen im Seniorenalter,
                                die noch im häuslichen Umfeld wohnen und tagsüber Betreuung benötigen.
                            </p>

                            {this.props.mobileVersion ? "" :
                                <div className="d-flex mt-3 mb-3">
                                    <div className="ml-auto mr-auto sprechen d-flex border p-2 rounded bg-secondary text-center">
                                        <Speech
                                            lang="de"
                                            displayText="Vorlesen"
                                            stop
                                            rate={0.8}
                                            text={`
                                        Lebenshilfe Rinteln e.V. ist eine Einrichtung zur Betreuung und Förderung von Menschen mit speziellen Bedürfnissen. Es gibt uns seit 1966. 
                                        Unsere Anfänge waren in angemieteten Räumen in der Altstadt von Rinteln. 1998 zogen wir von der Ostertorstraße in die Waldkaterallee auf das Gelände des ehem. 
                                        Britischen Militär Hospitals. Auf Grund der Möglichkeiten auf dem Gelände konnten wir weiter expandieren und neben der Platzzahlerhöhung von Frühförderung, 
                                        Heilpädagogischen Kindergarten und Tagesbildungsstätte auch drei Wohnheime (alles Einrichtungen speziell für Menschen mit Beeinträchtigungen) in Betrieb nehmen. 
                                        Am 01.09.2006 wurde der von der Heimaufsicht genehmigte Wohnbereich für Senioren mit geistiger Beeinträchtigung mit 24 Plätzen in Betrieb genommen. 
                                        Am 01.09.2015 hat unser Pflegeheim "Haus am Waldkater" seinen Betrieb aufgenommen und schon nach kurzer Zeit eine sehr gute Auslastung erreicht. 
                                        Am 01.09.2016 wurde der Regelkindergarten Integrative "Kindertagesstätte am Waldkater" eröffnet und im Jahr 2019 um eine Krippen- sowie die Integrationsgruppe erweitert.
                                        Im Jahr 2021 erfolgte der Umzug des Seniorenwohnens in das frisch umgebaute Haus Antonia.
                                        Seit 2024 bieten wir mit unserem Projekt „Stallpiraten“ den hier betreuten Menschen (pädagogisch) begleitete Interaktion mit unseren Tieren (zur Zeit Pferde, Ponys, Esel) an. 
                                        Heute werden bei uns ca. 500 Menschen in den verschiedenen Bereichen gefördert und betreut.
                                        Im Mai 2025 soll unser neuer Bereich „Tagespflege am Waldkater“ eröffnet werden. Die Tagespflege ist ein Angebot für Menschen im Seniorenalter, 
                                        die noch im häuslichen Umfeld wohnen und tagsüber Betreuung benötigen.
                                                `}
                                        />
                                        <span className="mt-2 ml-1 text-white">Vorlesen</span>

                                    </div>

                                </div>
                            }
                        </div>
                    </div>


                    <div className='col-12 col-lg-6 p-4 row'>
                        <div className="col-12 shadow-sm bg-white text-dark mt-2 pb-2 rounded d-flex flex-column justify-content-center">
                            <p className="mt-2">Lebenshilfe Rinteln Imagefilm</p>
                            <video style={{ width: "100%", borderRadius: "10px" }} controls poster="poster1.png" >
                                <source src="/video1.mp4" type="video/mp4" />
                                Dein Browser unterstützt das Video-Tag nicht.
                            </video>
                        </div>

                        <div className="col-12 shadow-sm bg-white text-dark mt-2 pb-2 rounded d-flex flex-column justify-content-center">
                            <p className="mt-2">Lebenshilfe Rinteln Kinospot</p>
                            <video style={{ width: "100%", borderRadius: "10px" }} controls poster='poster2.png' >
                                <source src="/video2.mp4" type="video/mp4" />
                                Dein Browser unterstützt das Video-Tag nicht.
                            </video>
                        </div>
                    </div>

                </div>
            </div>
        </div >
        );
    }
}

export default Start;