import React from 'react';
import Menu from '../components/menu';

class TagespflegeAmWaldkater extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        window.scrollTo(0, 0);
        return (<>



            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>

                <div className='container'>
                    <div className='bg-white rounded shadow-sm  p-4 m-5 text-secondary'  >

                        <h3 className="p-2 text-info bg-white m-0">Unsere Tagespflege</h3>

                        <>
                            <div className="container text-center ff-1 mt-3 text-white shadow-sm p-3 bg-info">
                                <h4>Sie fühlen sich in Ihrem Zuhause wohl, möchten oder können dort aber nicht den ganzen Tag über alleine sein?</h4>
                            </div>

                        </>

                        <p className='mt-5'>Unsere im Frühjahr 2025 errichtete Tagespflege bietet Ihnen behaglich eingerichtete Räumlichkeiten, nette Gesellschaft und Abwechslung. Damit schaffen wir einen Ort, der zum Verweilen und gemeinsamen Zeitverbringen einlädt.</p>
                        <p>Täglich erwarten Sie wechselnde Angebote mit verschiedensten Aktivitäten, die individuell auf jeden Tagesgast abgestimmt sind. Das abwechslungsreiche Freizeitprogramm können Sie nach Ihren Wünschen wahrnehmen. Ebenso haben Sie jederzeit die Möglichkeit, sich zurückzuziehen und Abgeschiedenheit sowie Ruhe zu genießen.</p>

                        <h5>Leistungen</h5>
                        <p>Mit unserer Tagespflege am Waldkater schließen wir eine Lücke zwischen Häuslichkeit und Pflegeheim. Wir unterstützen unsere Gäste dabei, im Rahmen ihrer individuellen Fähigkeiten und Wünsche selbstbestimmt am Leben teilzunehmen und aktiv zu sein.</p>
                        <ul>
                            <li>Flexible Buchung: einzelne Tage oder die ganze Woche</li>
                            <li>Mehrere Mahlzeiten pro Tag: Frühstück, Mittagessen und Kaffee</li>
                            <li>Entlastung der Angehörigen</li>
                            <li>Pflegepersonal für Medikamentengabe und pflegerische Betreuung (z. B. Toilettengang, Nahrungsaufnahme)</li>
                            <li>Hol- und Bringservice nach Wunsch</li>
                            <li>Individuelle Betreuung und Tagesgestaltung mit Ausflügen, Bewegungsübungen, Spielen und Kochen</li>
                        </ul>

                        <h5>Kostenloser Schnuppertag</h5>
                        <p>Vereinbaren Sie gerne einen Termin und besuchen Sie uns. Machen Sie sich einen ersten Eindruck unserer Räumlichkeiten, lernen Sie unser Pflegepersonal kennen und erhalten Sie eine Beratung zu unseren Leistungen. Falls Ihnen die Tagespflege am Waldkater zusagt, können Sie direkt einen kostenlosen Schnuppertag für sich oder Ihren Angehörigen festlegen.</p>

                        <hr />
                        <p><strong>Sie möchten weiterhin in Ihrem Zuhause leben, Selbstbestimmung und Lebensqualität genießen und benötigen dabei Unterstützung?</strong> Dann sind Sie bei uns in der Tagespflege am Waldkater genau richtig.</p>
                        <hr />

                        <h5>Unser Team</h5>
                        <p>Das Team der Tagespflege am Waldkater besteht aus Fachkräften, Pflegehelfern und Betreuungskräften, die sich um Ihr Wohl kümmern.</p>

                        <h5>Kontakt / Adresse</h5>
                        <p><strong>Miriam Böger</strong></p>

                        <blockquote>„Pflege ist das tägliche Geschenk an jene, die uns am meisten brauchen.“</blockquote>





                    </div>



                </div>

            </div>
        </>);
    }
}

export default TagespflegeAmWaldkater;