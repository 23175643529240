import React from 'react';

import MaterialTab from '../components/materialTab';

import Menu from '../components/menu';
import Carusel from '../components/carusel';
import caruselBilder from "../css/image/aktuelles/carusel";

const tabContents = [

    {
        title: "Termine & Veranstaltungen ",
        content: <div className="p-3 text-secondary ">
            <h3>Termine & Veranstaltungen </h3>
            <div className="row m-0">

                <p className="col-12 m-0 mt-4 mb-2"><b>Schließungszeiten integrative Kindertagesstätte 2024/2025:</b></p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag</p>
                <p className="col-12 col-md-6 m-0 mt-1">04.10.2024 + 01.11.2024</p>
                <p className="col-12 col-md-6 m-0 mt-1">Weihnachtsferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">23.12.2024 – 03.01.2025</p>

                <p className="col-12 col-md-6 m-0 mt-1">Osterferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">16.04.2025 – 21.04.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag</p>
                <p className="col-12 col-md-6 m-0 mt-1">02.05.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag nach Himmelfahrt</p>
                <p className="col-12 col-md-6 m-0 mt-1">30.05.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Sommerferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">21.07.2025 - 08.08.2025</p>


                <p className="col-12 m-0 mt-4 mb-2"><b>Schließungszeiten Heilpädagogischer Kindergarten 2024/2025:</b></p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag</p>
                <p className="col-12 col-md-6 m-0 mt-1">01.11.2024</p>
                <p className="col-12 col-md-6 m-0 mt-1">Weihnachtsferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">23.12.2024 – 03.01.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Osterferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">16.04.2025 – 21.04.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag</p>
                <p className="col-12 col-md-6 m-0 mt-1">02.05.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag nach Himmelfahrt</p>
                <p className="col-12 col-md-6 m-0 mt-1">30.05.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Sommerferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">21.07.2025 - 08.08.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Herbstferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">13.10.2025 – 17.10.2025</p>

                <p className="col-12 m-0 mt-4 mb-2"><b>Schließungszeiten Schule am Waldkater anerk. Tagesbildungsstätte 2024/2025:</b></p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag</p>
                <p className="col-12 col-md-6 m-0 mt-1">01.11.2024</p>
                <p className="col-12 col-md-6 m-0 mt-1">Weihnachtsferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">23.12.2024 – 03.01.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Osterferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">16.04.2025 – 21.04.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag</p>
                <p className="col-12 col-md-6 m-0 mt-1">02.05.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag nach Himmelfahrt</p>
                <p className="col-12 col-md-6 m-0 mt-1">30.05.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Sommerferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">14.07.2025 - 01.08.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Herbstferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">13.10.2025 – 17.10.2025</p>

                <p className="col-12 m-0 mt-4 mb-2"><b>Betriebsurlaubszeiten Werkstätten für behinderte Menschen der PLSW für das Jahr 2024/2025:</b></p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag</p>
                <p className="col-12 col-md-6 m-0 mt-1">01.11.2024</p>
                <p className="col-12 col-md-6 m-0 mt-1">Weihnachtsferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">23.12.2024 – 31.12.2024</p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag</p>
                <p className="col-12 col-md-6 m-0 mt-1">02.05.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Brückentag nach Himmelfahrt</p>
                <p className="col-12 col-md-6 m-0 mt-1">30.05.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Sommerferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">14.07.2025 – 25.07.2025</p>
                <p className="col-12 col-md-6 m-0 mt-1">Weihnachtsferien</p>
                <p className="col-12 col-md-6 m-0 mt-1">22.12.2025 – 02.01.2026</p>

            </div>
        </div>
    },
    //{
    //    title: "Projekte",
    //    content: <div className="p-3 text-secondary ">
    //        <h3>Projekte </h3>
    //        <div className="row m-0">
    //            <div className="col-12 col-md-6">
    //                <h5> Assistenz beim Wohnen</h5>
    //                <p>
    //                    Die Räumlichkeiten der Assistenz beim Wohnen werden aufgrund kontinuierlicher Klientenzahl (zur Zeit 40) ins Haus Katharina verlegt und erweitert.
    //                    Zur Zeit finden dort Renovierungsarbeiten statt – es entsteht ein neuer Treffpunkt für gemeinsame Aktivitäten mit Küche, Aufenthaltsräumen und Sanitäranlagen.
    //                </p>
    //            </div>
    //            <div className="col-12 col-md-6">
    //
    //                <h5>Erweiterung integrative Kita</h5>
    //                <p>
    //                    Planung und Bau einer zusätzlichen Krippengruppe mit ca. 15 Plätzen. Der Baustart ist geplant für Sommer 2021, Fertigstellung im Frühjahr 2022.
    //                    {/*  Planung und Bau einer zusätzlichen Krippengruppe Der Baustart ist geplant für
    //                    Sommer 2021. Dort wo jetzt Lagerräume angesiedelt sind, entsteht ein ganz neuer
    //                    moderner Kindergarten, in dem ca. 18 Kinder zukünftig ihren Kita-Alltag erleben
    //                    dürfen. In diesen neu geschaffenen Räumlichkeiten wird die Integrationsgruppe
    //                    einziehen. Dadurch können wir eine weitere Krippengruppe mit ca. 15 Plätzen
    //                    anbieten. */}
    //                </p>
    //            </div>
    //
    //
    //        </div>
    //    </div>
    //},
    /*  {
         title: "Besuchszeiten",
         content: <div className="p-3 text-secondary ">
             <h3>Besuchszeiten </h3>
             <div>
                 <h5> Zurzeit gelten noch unsere Corona Besuchszeiten:</h5>
                 <ul>
                     <li>Letzter Einlass im Pflegebereich Haus am Waldkater 17:30 Uhr</li>
                 </ul>
                 <p>
                     Nach Wegfall der Corona Einschränkung möchten wir generell wieder auf
                     reglementierte Besuchszeiten verzichten.
                 </p>
             </div>
         </div>
     }, */
    /*   {
          title: "Schule am Waldkater",
          content: <div className="p-3 text-secondary ">
              <h3>Schule am Waldkater</h3>
              <div className="row">
                  <div className="col-12 col-md-6 m-auto">
                      <img src={Bild_SchuleAmWaltkater} alt="" width="100%" height="300px" className="cover rounded" />
                      <p className="mt-2"><small><i>
                          v.l. Melvin Maximilian John, Karina Olschewski, Kevin Jablonski
                      </i> </small></p>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                      <h5>Schulentlassungsfeier in der Schule am Waldkater, Rinteln. e.V.</h5>
                      <p className="small">
                          Am 13. Juli 2022 feierten Kevin Jablonski und Melvin Maximilian John ihre Entlassungsfeier.
                          Beide haben ihre Schulzeit absolviert und wurden von der Leitung der Schule,
                          Frau Karina Olschewski, im Rahmen einer kleinen Feierlichkeit in die „Arbeitswelt“ entlassen.
                          <br /><br />
                          Die Schulabsolventen erhielten Abschiedsgeschenke von den Mitarbeiterinnen der Abschlussstufe,
                          sowie von den MitschülerInnen und der Schulleiterin. Frau Olschewski überreichte den
                          Schulabgängern eine Mappe mit den jeweiligen Abschlusszeugnissen und einem kurzen Überblick
                          über<br />
                          die vergangenen Jahre. Melvin wurden bereits in der Frühförderung und im Heilpädagogischen
                          Kindergarten der Lebenshilfe Rinteln e.V. betreut. Er wechselte zwischenzeitlich in andere
                          Einrichtungen, kam jedoch zurück nach Rinteln und besuchte die Schule am Waldkater (anerk.
                          Tagesbildungsstätte). Kevin besuchte die Frühförderung, den Heilpädagogischen Kindergarten und
                          die Schule am Waldkater (anerk. Tagesbildungsstätte) der Lebenshilfe Rinteln e.V.
                          <br /><br />
                          Somit haben Beide den Großteil ihres Lebens in der Lebenshilfe Rinteln e.V. verbracht, es ist ein
                          Zuhause geworden. Demnach verließen die Schüler die Einrichtung mit einem „weinenden“ und
                          einem
                          „lachenden“ Auge.
                          Das Kollegium und die Schülerschaft wünschen Beiden alles Gute für die Zukunft.
                      </p>
                  </div>
              </div>
  
  
          </div>
      } */
]


class Aktuelles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        window.scrollTo(0, 0)
        return (
            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="container">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>
                <>
                    <div className="container text-center shadow-sm p-0 rounded">
                        <h3 className="p-2 text-info bg-white m-0">Aktuelle Informationen</h3>
                        <Carusel bilder={caruselBilder()} height="400px" width="100%" className="rounded shadow-sm" />
                    </div>
                </>

                <>
                    <div className={"mt-4 bg-white shadow-sm rounded mb-4 p-3 container"}>
                        <MaterialTab tabs={tabContents} {...this.props} />
                    </div>
                </>

            </div>);
    }
}

export default Aktuelles;